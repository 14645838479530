.costText {
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 15px;
    text-align: left;
    width: 95%;
    margin: 0 auto;
  }

  .graphContainer {
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgb(0, 0, 0);
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }
  .copyButton {
    background-color: rgb(82, 69, 238);
    border: none;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 11px;
    height: 20px;
    outline: none;
    width: 100px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .copyButton:hover {
    background-color: rgb(0,0,0);
  }

  .resetButton {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(82, 69, 238);
    border-radius: 3px;
    color: rgb(0, 0, 0);
    font-size: 11px;
    height: 20px;
    outline: none;
    width: 100px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .resetButton:hover {
    background-color: rgb(0,0,0);
    color: white;
  }