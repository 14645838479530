.new-line {
    white-space: pre-line;
  }

.chatContainer {
    background-color: #ffffff; 
  }

.inputContainer {
    margin: 0 auto;
    display: flex;
    flex-flow: row;
  }

.searchBar {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(82, 69, 238);
    border-radius: 5px;
    display: block;
    font-size: 12px;
    height: 40px;
    outline: none;
    padding: 0 10px;
    width: 75%;
    margin-left: auto;
    margin-bottom: 20px;
    margin-top: 0;
  }

.generateButton {
    background-color: rgb(82, 69, 238);
    border: none;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 12px;
    height: 40px;
    outline: none;
    width: 18%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .generateButton:hover {
    background-color: rgb(0,0,0);
  }
  
  .generateButton:disabled {
    background-color: rgb(0, 0, 0, 0.5);
  }

  .cost {
    font-size: 12px;
  }

  .working {
    position: fixed;
    width: 100%;
    height: 100%; 
    top: 30%;
    margin: 0 auto;
    left:47%;
    text-align: center;  
    z-index: 2; 
  }