@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
html, body {
  height: 100%;
}

.headerGraphic {
  display: block;
  margin-top:5px;
  width: 120px;
}

.noteText {
  font-family: 'Roboto';
  font-weight: 200;
  font-size: 13px;
  font-style: italic;
  text-align: left;
}

.errorMsg {
  font-family: 'Roboto';
  font-weight: 800;
  font-size: 15px;
  color:crimson;
  text-align: left;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.instructions {
  position: absolute;
  top:0;
  width: 50%;
}